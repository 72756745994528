<template>
  <div style="background: #ffffff;">
		<div class="top_action no_print">
			<div style="display: flex;justify-content: space-between;">
				<div>
					<el-button type="primary" icon="el-icon-printer" @click="printerAction()">{{$t('i18nn_886072e393710563')}}</el-button>
					<!-- <el-button type="warning" icon="el-icon-download" @click="exportPdf()">PDF</el-button> -->
					<!-- <el-button type="warning" icon="el-icon-download" @click="exportPdfPage()">PDF</el-button> -->
					<span style="font-size:14px">
						如需下载PDF,选择<strong style='color:red;'>{{$t('i18nn_ea80f4411a8408b8')}}</strong>{{$t('i18nn_d34e97bbd9523da6')}}<strong style='color:red;'>另存为PDF</strong>;
						还可以去掉<strong style='color:red;'>{{$t('i18nn_f54ebccbce73a3d6')}}</strong>勾选;
					</span>
				</div>
				<div>
					<el-radio-group v-model="activeLang" size="small" @change="switchLang">
					  <el-radio-button :label="'cn'">{{$t('topBar.Chinese')}}</el-radio-button>
					  <el-radio-button :label="'en'">{{$t('topBar.English')}}</el-radio-button>
					</el-radio-group>
				</div>
			</div>
		</div>
		
		
		<!-- <div class="no_print">
			<span>Language:</span>
			<el-select v-model="activeLang" size="small" :placeholder="$t('FormMsg.Please_select')" @change="switchLang">
				<el-option :label="$t('topBar.Chinese')" :value="'cn'"></el-option>
				<el-option :label="$t('topBar.English')" :value="'en'"></el-option>
			</el-select>
		</div> -->
    <router-view/>
  </div>
</template>

<script>
	// import {
	// 	exportPdfByPrint,
	// 	exportPdfPageByPrint
	// } from '@/utils/pdfExport.js'
	export default {
		data() {
			return {
				activeLang: this.$i18n.locale
			};
		},
		mounted() {
			console.log('monted');
			// this.switchLang('en');
		},
		methods: {
			//打印
			printerAction() {
			  window.print();
			  // this.$print(this.$refs.print);
			},
			//导出pdf
			// exportPdf(){
			// 	let name = 'print';
			// 	try{
			// 		name = window.location.href;
			// 		name = name.split("/");
			// 		name = name[name.length-1];
			// 		name = name.split("?");
			// 		name = name[0];
			// 	}catch(e){
			// 		//TODO handle the exception
			// 		console.log(e);
			// 	}
				
			// 	exportPdfByPrint(name,'.printMain');
			// },
			//导出pdf
			// exportPdfPage(){
			// 	// let name = 'print';
			// 	// try{
			// 	// 	name = window.location.href;
			// 	// 	name = name.split("/");
			// 	// 	name = name[name.length-1];
			// 	// 	name = name.split("?");
			// 	// 	name = name[0];
			// 	// }catch(e){
			// 	// 	//TODO handle the exception
			// 	// 	console.log(e);
			// 	// }
				
			// 	// exportPdfPageByPrint(name,'.printMain');
				
			// 	let msg = `
			// 	请点击<strong>打印</strong>,
			// 	选择<strong style='color:red;'>目标打印机</strong>为<strong style='color:red;'>另存为PDF</strong>,
			// 	再点击<strong>保存</strong>即可.`;
				
			// 	this.$alert(msg, this.$t('i18nn_cc62f4bf31d661e3'), {
			// 		// type: 'warning',
			// 		dangerouslyUseHTMLString: true,
			// 		callback: action => {
			// 			// this.printerAction();
			// 		}
			// 	});
			// },
			
			
			//切换语言
			switchLang(lang) {
				this.$i18n.locale = lang;
				this.$store.dispatch('UPDATE_LANG', lang);
				this.$message.success(this.$t('topBar.switchLangSuccess'));
			},
		}
	}
</script>

<style scoped lang="less">
	@import url(../assets/css/printer.less);
</style>
